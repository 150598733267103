import React, {useState, useEffect, useContext} from 'react';
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { makeStyles } from '@mui/styles';
import ConstructionIcon from '@mui/icons-material/Construction';
import AppDrawer from "../AppDrawer";
import {UserContext} from "../../../context/userContext";
import {AccountContext} from "../../../context/accountContext";
import {Box} from "@mui/material";
import Cookies from "js-cookie";
import {appConfig, getTokenHeader} from "../../../utils/settings";
import {theme as globalTheme} from "../../../theme";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Table from "@mui/material/Table";

const PREFIX = 'Profile';
const classes = {
   root: `${PREFIX}-root`,
   paper: `${PREFIX}-paper`,
   titleRow: `${PREFIX}-titleRow`,
   itemRow: `${PREFIX}-itemRow`,
}
const Root = styled('div')(({ theme }) => ({
   [`&.${classes.root}`]: {
      display: "flex",
      paddingTop: '64px'
   },
   [`& .${classes.paper}`]: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      width: '100%',
   },

   [`& .${classes.titleRow}`]: {
      display: 'flex',
      justifyContent: 'flex-start'
   },
   [`& .${classes.itemRow}`]: {
      paddingLeft: '10px',
   },
}))

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
   accountInfoContainer: {
      padding: theme.spacing(3),
   }
}));


export default function Profile(props) {
   // Use the styles
   const classes2 = useStyles();
   const {user, userLoading} = useContext(UserContext);
   const [fullUser, setFullUser] = useState({});
   const [fullUserLoading, setFullUserLoading] = useState(true);

   // Context is lost over app refresh. Reset from cookie if it exists.
   useEffect(() => {
      if(!userLoading) {
         // go retrieve full user
         (async () => {
            const options = {method: "GET", headers: getTokenHeader()};
            const response = await fetch(appConfig.authHost +'/api/v1/accounts/' + user.accountId + '/user/' + user.userId, options)
               .catch(function (error) {
               console.error('Request failure: ', error);
            });
            const fullUser = await response.json();
            console.log(fullUser);
            setFullUser(fullUser);
            setFullUserLoading(false);
         })();
      }
   }, [userLoading]);

   const phoneNumber = fullUser.phone ? fullUser.phone: 'Not set'

   return (
      <Root className={classes.root}>
         <AppDrawer/>
         <Paper className={classes.paper}>
               <div style={{display: 'flex', justifyContent: 'center', paddingTop: '50px'}}>
                  <Box border={1} borderRadius={2} borderColor={'border.default'} sx={{padding: '15px', width: '100%'}}>
                     <Grid container sx={{display: 'flex', alignItems: 'center'}}>
                        <Grid item xs={12} className={classes.titleRow}>
                           <Typography variant="h5" sx={{fontWeight: '400', color: 'title.header'}} gutterBottom>
                              Profile Info
                           </Typography>
                        </Grid>
                     </Grid>
                     <TableContainer>
                        <Table>
                           <TableRow>
                              <TableCell><Typography sx={{fontSize: 14, fontWeight: '500', color: 'title.subHeader'}}>First Name</Typography></TableCell>
                              <TableCell><Typography sx={{fontWeight: '400', color: 'title.value'}}>{fullUser.firstName}</Typography></TableCell>
                           </TableRow>
                           <TableRow>
                              <TableCell><Typography sx={{fontSize: 14, fontWeight: '500', color: 'title.subHeader'}}>Last Name</Typography></TableCell>
                              <TableCell><Typography sx={{fontWeight: '400', color: 'title.value'}}>{fullUser.lastName}</Typography></TableCell>
                           </TableRow>
                           <TableRow>
                              <TableCell><Typography sx={{fontSize: 14, fontWeight: '500', color: 'title.subHeader'}}>Email</Typography></TableCell>
                              <TableCell><Typography sx={{fontWeight: '400', color: 'title.value'}}>{fullUser.email != null ? fullUser.email: 'Not Set'}</Typography></TableCell>
                           </TableRow>
                           <TableRow>
                              <TableCell><Typography sx={{fontSize: 14, fontWeight: '500', color: 'title.subHeader'}}>Phone</Typography></TableCell>
                              <TableCell><Typography sx={{fontWeight: '400', color: 'title.value'}}>{phoneNumber}</Typography></TableCell>
                           </TableRow>
                        </Table>
                     </TableContainer>

                  </Box>
               </div>


         </Paper>
      </Root>
   );


}