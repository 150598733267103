import React, { Component } from 'react';
import './DwFooter.css';


class DwFooter extends Component {
  render() {
    return (
      <footer className="page-footer">
        <div className="footer-copyright">
          <div className="container">© 2024 DeRemWorks llc</div>
        </div>
      </footer>
    );
  }
}
export default DwFooter;