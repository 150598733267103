import React, { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import {InputAdornment} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import formatter from "../../utils/formatter";
import Grid from "@mui/material/Unstable_Grid2";
import {styled} from "@mui/material/styles";

const PREFIX = 'TransactionDetails';
const classes = {
    root: `${PREFIX}-root`
}
const Root = styled('form')(({ theme }) => ({
    [`&.${classes.root}`]: {
        flexGrow: 1,
    },
}))

export default function TransactionDetails(props) {
    const open = props.open;
    const handleClose = props.handleClose;
    const transactionData = props.transactionData;
    const handleTransactionDetailEdits = props.handleTransactionDetailChanges;
    const handleTransactionDetailSave = props.handleTransactionDetailSave;
    const handleTransactionDelete = props.handleTransactionDelete;

    const handleAmountChange = e =>  {
        const transactionDetailData = {...transactionData, amount: e.target.value};
        handleTransactionDetailEdits(transactionDetailData);
    }

    const handleDescriptionChange = e => {
        const transactionDetailData = {...transactionData, description: e.target.value};
        handleTransactionDetailEdits(transactionDetailData);
    }

    const handleDateChange = e => {
        const epochSeconds = new Date(e.target.value).getTime() / 1000;
        let utcOffsetSeconds = new Date().getTimezoneOffset() * 60;
        let transactionInstant = epochSeconds + utcOffsetSeconds;

        const transactionDetailData = {...transactionData, transactionDate: transactionInstant}
        handleTransactionDetailEdits(transactionDetailData);
    }

    return (
       <Root>
            <Dialog open={open} onClose={handleClose} maxWidth={'md'}>
                <DialogTitle>Transaction Details</DialogTitle>
                <DialogContent>
                    <Grid container rowSpacing={1} columnSpacing={2} alignItems={"center"}>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth sx={{ m: 1 }}>
                                <TextField
                                    id="date"
                                    label="Transaction Day"
                                    type="date"
                                    defaultValue={formatter.getLocalizedCalendarDateFromEpochSeconds(transactionData.transactionDate)}
                                    onChange={handleDateChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth sx={{ m: 1 }}>
                                <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-amount"
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    label="Amount"
                                    value={transactionData.amount}
                                    onChange={handleAmountChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormControl fullWidth sx={{ m: 1 }}>
                                <InputLabel htmlFor="outlined-adornment-amount">Description</InputLabel>
                                <OutlinedInput
                                   id="outlined-adornment-amount"
                                   label="Description"
                                   value={transactionData.description}
                                   onChange={handleDescriptionChange}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button color='error' onClick={handleTransactionDelete}>Delete</Button>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button color='secondary' onClick={handleTransactionDetailSave}>Save</Button>
                </DialogActions>
            </Dialog>
        </Root>
    );
}