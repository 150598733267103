import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import {AuthContext} from "./authContext";
import { UserContext} from "./userContext";

function ProtectedRoute({ children }) {
   const isLoggedIn = useContext(AuthContext);

   if (!isLoggedIn) {
      // Redirect to the login page if no auth token is present
      return <Navigate to="/login" replace />;
   }

   // If both auth and user context exist, render the protected content
   return children;
}

export default ProtectedRoute;

