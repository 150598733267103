import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import { makeStyles } from '@mui/styles';
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme) => ({
   tile: {
      height: '60px',
      display: "flex",
      alignItems: "center",
      padding: "10px",
   },

}));

export default function BudgetStatusBanner(props) {
   const classes = useStyles();
   const totalBudget = props.totalBudget;
   const totalTransactions = props.totalTransactions;

  // You have n dollars left

  // You have spent n dollars of your budgeted n dollars.

  // You have n% left and n days left in the month

   return (
         <Grid container xs={12} spacing={2} style={{marginBottom: "25px"}}>
            <Grid item sm={4} xs={12}><Paper className={classes.tile}><Typography variant="h5">Budget: ${props.totalBudget}</Typography></Paper></Grid>
            <Grid item sm={4} xs={12}><Paper className={classes.tile}><Typography variant="h5">Spent: ${props.totalTransactions}</Typography></Paper></Grid>
            <Grid item sm={4} xs={12}><Paper className={classes.tile}><Typography variant="h5">Remaining: ${(props.totalBudget - props.totalTransactions).toFixed(2)}</Typography></Paper></Grid>
         </Grid>
   );
}