import React, {useContext, useEffect, useState} from 'react';
import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import BudgetSettings from "./BudgetSettings";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {Box} from "@mui/material";
import CustomCategories from "./CustomCategories";
import AppDrawer from "../AppDrawer/AppDrawer";

const PREFIX = 'Settings';
const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
}
const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    paddingTop: '64px'
  },
  [`& .${classes.paper}`]: {
    color: theme.palette.text.secondary,
    width: '100%'
  }
}))


export default function Settings(props) {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
     <Root className={classes.root}>
       <AppDrawer/>
       <Paper className={classes.paper}>
         <TabContext value={value}>
           <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
             <TabList onChange={handleChange} aria-label="lab API tabs example">
               <Tab label="Budget Setup" value="1" />
               <Tab label="Custom Categories" value="2" />
             </TabList>
           </Box>
           <TabPanel value="1"><BudgetSettings></BudgetSettings></TabPanel>
           <TabPanel value="2"><CustomCategories></CustomCategories></TabPanel>
         </TabContext>
       </Paper>
     </Root>
    );
  }


