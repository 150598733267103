import React from 'react';

import swoop from '../../images/belliercurve1920.png';
import graph1 from "../../images/line_graph_down.png";
import graphic2 from "./TiLGraphic2_Relations.png";
import donuts from '../../images/donut_graph_combined.png'
import report from "./TiS_report.png";
import LoginHeader from "./LoginHeader";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import {styled} from "@mui/material/styles";

const PREFIX = 'Login';
const classes = {
   root: `${PREFIX}-root`,
   paper: `${PREFIX}-paper`,
   roundedBtn: `${PREFIX}-roundedBtn`,
   upsellContainer: `${PREFIX}-upSellContainer`,
   upsellHeader: `${PREFIX}-upSellHeader`,
   placeholderImage: `${PREFIX}-placeholderImage`,
   descriptionBlock: `${PREFIX}-descriptionBlock`,
   descriptionTextR: `${PREFIX}-descriptionTextR`,
   descriptionTextL: `${PREFIX}-descriptionTextL`,
   textField: `${PREFIX}-textField`,
   extraLongInput: `${PREFIX}-extraLongInput`,
   sectionHeader: `${PREFIX}-sectionHeader`,
   divider: `${PREFIX}-divider`,
   register: `${PREFIX}-register`
}


const Root = styled('div')(({ theme }) => ({
   [`&.${classes.root}`]: {
      '& .MuiInputBase-root': {
         background: 'white',
      },
   },
   [`& .${classes.paper}`]: {
      textAlign: 'center',
      color: theme.palette.text.secondary,
   },
   [`& .${classes.roundedBtn}`]: {
      margin: theme.spacing(1),
      borderRadius: "5em"
   },
   [`& .${classes.upsellContainer}`]: {
      backgroundColor: "#fafafa",
   },
   [`& .${classes.upsellHeader}`]: {
      backgroundColor: "#f5f5f5",
      paddingTop: "15px",
   },
   [`& .${classes.placeholderImage}`]: {
      width: "350px",
      height: "300px",
      border: "2px solid #263238",
      borderRadius: "10px",
   },
   [`& .${classes.descriptionBlock}`]: {
      paddingTop: "15px",
      paddingBottom: "25px",
      borderBottom: "1px solid #eceff1",
   },
   [`& .${classes.descriptionTextL}`]: {
      paddingRight: "25px",
   },
   [`& .${classes.descriptionTextR}`]: {
      paddingLeft: "25px",
   }
}));

export default function LoginPage(props) {
  return (
     <Root className={classes.root}>
        <Paper className={classes.paper}>
           <Grid container className={classes.upsellContainer}>
              <LoginHeader></LoginHeader>
              <Grid item xs={12} className={classes.upsellHeader}>
                <Typography variant={"h2"} color={"primary"} align={"center"}>
                   We make budgeting easy.
                </Typography>
              </Grid>
              <Grid container className={"swoop"}>
                <img src={swoop} alt="swoop" width="100%" height="auto"/>
              </Grid>
              <Container maxWidth={"md"}>
                 <Grid container className={"upsell"} direction={"row"} justify={"center"} align={"center"}>
                    <Grid item xs={12}><Typography variant={"h5"} color={"primary"}>Solutions for the modern family</Typography></Grid>
                    {/*<Grid container direction={"row"} justify={"center"}>*/}
                    {/*   <Grid><Button variant="outlined" color="secondary" className={classes.roundedBtn}> Visualize </Button></Grid>*/}
                    {/*   <Grid><Button variant="outlined" color="secondary" className={classes.roundedBtn}> Track </Button></Grid>*/}
                    {/*   <Grid><Button variant="outlined" color="secondary" className={classes.roundedBtn}> Report </Button></Grid>*/}
                    {/*</Grid>*/}

                    <Grid container className={classes.descriptionBlock} direction={"row"} justify={"center"} alignItems={"center"}>
                       <Grid><img src={graph1} width={325} alt="Graph1"/></Grid>
                       <Grid>
                          <Typography className={classes.descriptionTextR} variant={"h4"}>Visualize spend </Typography>
                       </Grid>
                    </Grid>
                    <Grid container className={classes.descriptionBlock} direction={"row"} justify={"center"} alignItems={"center"}>
                       <Grid>
                          <Typography className={classes.descriptionTextL} variant={"h4"}>Budget by category</Typography>
                       </Grid>
                       <Grid><img src={donuts} alt="Graphic2"/></Grid>
                    </Grid>
                    <Grid container className={classes.descriptionBlock} direction={"row"} justify={"center"} alignItems={"center"}>
                       <Grid><img width={"300px"} src={report} alt="Reports"/></Grid>
                       <Grid>
                          <Typography className={classes.descriptionTextR} variant={"h4"}>Detailed History </Typography>
                       </Grid>
                    </Grid>
                 </Grid>
              </Container>
           </Grid>
        </Paper>
     </Root>
  );
}



