import React, {useState, useEffect, useContext} from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import { styled } from '@mui/material/styles';
import {getTokenHeader} from "../../utils/settings";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {Box} from "@mui/material";


const PREFIX = 'AddFamily';
const classes = {
   root: `${PREFIX}-root`,
   paper: `${PREFIX}-paper`,
   rightIcon: `${PREFIX}-rightIcon`,
}
const Root = styled('form')(({ theme }) => ({
   [`&.${classes.root}`]: {
      flexGrow: 1,
   },
   [`& .${classes.paper}`]: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      width: '100%'
   },
   [`& .${classes.rightIcon}`]: {
      marginLeft: theme.spacing(1),
   },
}))

export default function AddFamily(props) {
   const open = props.open;
   const handleClose = props.closeHandler;
   const setFamilyMembersLoading = props.saveHandler;
   const [familyMemberName, setFamilyMemberName] = React.useState('');


   // Change handlers
   const handleFamilyMemberNameChange = e => {
      setFamilyMemberName(e.target.value);
   }

   return (
      <Root>
         <Dialog open={open} onClose={handleClose} maxWidth={'md'}>
            <DialogTitle>Add Family Member</DialogTitle>
            <DialogContent>
               <Grid container sx={{paddingTop: "5px"}} rowSpacing={2} columnSpacing={2} alignItems={"center"}>
                  <Grid item xs={12} sm={12}>
                     <TextField
                        id="name"
                        label="Name"
                        placeholder="Name"
                        fullWidth
                        InputLabelProps={{
                           shrink: true,
                        }}
                        variant="outlined"
                        onChange={handleFamilyMemberNameChange}
                     />
                  </Grid>
               </Grid>
            </DialogContent>
            <DialogActions>
               <Button onClick={props.closeHandler}>Cancel</Button>
               <Button color='secondary' onClick={saveFamilyMember}>Save</Button>
            </DialogActions>
         </Dialog>
      </Root>
   );

   function saveFamilyMember() {
      let reqHeaders = getTokenHeader();
      reqHeaders = {...reqHeaders, "Content-Type": "application/json"};

      console.log(props.accountId);
      console.log(familyMemberName);
      console.log(props.serverUrl);
      fetch(props.serverUrl + '/api/v1/family', {
         method: 'POST',
         headers: reqHeaders,
         body: JSON.stringify({
            accountId: props.accountId,
            firstName: familyMemberName
         })
      }).then(function (data) {
         handleClose();
         setFamilyMembersLoading(true);
      }).catch(function (error) {
            console.error('Request failure: ', error);
      });
   }
}



