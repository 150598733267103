import React, {useEffect, useState} from 'react';
import './App.css';

import { Routes, Route} from 'react-router-dom';
import Settings from './components/SettingsPage/Settings';
import Contact from './components/ContactPage/Contact';
import Dashboard from './components/Dashboard/Dashboard'
import DwFooter from "./components/DwFooter";
import Transactions from "./components/Transactions/Transactions";
import Cookies from "js-cookie";
import RegistrationPage from "./components/RegistrationPage/RegistrationPage";
import LoginPage from "./components/LoginPage/LoginPage";
import {AuthContext} from "./context/authContext";
import ProtectedRoute from "./context/ProtectedRoute";
import {UserContext} from "./context/userContext";
import {AccountContext} from "./context/accountContext";
import {appConfig, getTokenHeader} from "./utils/settings";
import ReactGA from "react-ga4";
import Profile from "./components/AppDrawer/AccountMenu/Profile";
import MortgageCalculator from "./components/Calculators/MortgageCalculator";
import AccountFamily from "./components/AccountFamily/AccountFamily";


export default function App(props) {

   const existingToken = Cookies.get("token");
   const [isLoggedIn, setIsLoggedIn] = useState(false);

   const [authToken, setAuthToken] = useState(existingToken);
   const [user, setUser] = useState(null);
   const [userLoading, setUserLoading] = useState(true);
   const [accountUsers, setAccountUsers] = useState([]);
   const [accountFamily, setAccountFamily] = useState([]);
   const [accountUsersLoading, setAccountUsersLoading] = useState(true);
   const [accountFamilyLoading, setAccountFamilyLoading] = useState(true);
   const [accountCategories, setAccountCategories] = useState([]);
   const [loadingAccountCategories, setLoadingAccountCategories] = useState(false);
   const [reloading, setReloading] = useState(true);

   ReactGA.initialize("G-3M9XM42PZ1");

    // Context is lost over app refresh. Reset from cookie if it exists.
    useEffect(() => {
        if(reloading) {
            // Update the document title using the browser API
            const userCookie = Cookies.get("user");
            if(userCookie) {
                setUser(JSON.parse(atob(userCookie)));
                setIsLoggedIn(true);
                setReloading(false);
                setUserLoading(false);
            }
        }
    });

    // Account category fetching
    useEffect(() => {
       if(isLoggedIn) {
          (async () => {
             const options = {method: "GET", headers: getTokenHeader()};
             const response = await fetch(appConfig.host +'/api/v1/categories', options);
             const categories = await response.json();
             setLoadingAccountCategories(false);
             setAccountCategories(categories);
          })();
       }
    }, [loadingAccountCategories, isLoggedIn]);

    // Account User Fetching
    useEffect(() => {
       if(isLoggedIn) {
          (async () => {
             const options = {method: "GET", headers: getTokenHeader()};
             const response = await fetch(appConfig.authHost + '/api/v1/accounts/' + user.accountId + '/user/users', options);
             const users = await response.json();
             setAccountUsersLoading(false);
             setAccountUsers(users);
          })();
       }
    }, [isLoggedIn]);

   // Account Family Fetching
   useEffect(() => {
      if(isLoggedIn) {
         (async () => {
            const options = {method: "GET", headers: getTokenHeader()};
            const response = await fetch(appConfig.host + '/api/v1/family/', options);
            const family = await response.json();
            setAccountFamilyLoading(false);
            setAccountFamily(family);
         })();
      }
   }, [accountFamilyLoading, isLoggedIn]);




    return (
       <AuthContext.Provider value={{ authToken, setAuthToken, isLoggedIn, setIsLoggedIn }}>
           <div className="App">
              <UserContext.Provider value={{ user, setUser, userLoading, setUserLoading}}>
                 <AccountContext.Provider value={{ accountCategories, loadingAccountCategories, setLoadingAccountCategories,
                    accountUsers, accountUsersLoading,
                    accountFamily, setAccountFamily,
                    accountFamilyLoading, setAccountFamilyLoading}}>
                    <Routes>
                       <Route path='/login' element={<LoginPage/>} />
                       <Route path='/register' element={<RegistrationPage/>} />
                       <Route path='/' element={<ProtectedRoute><LoginPage/></ProtectedRoute>} />
                       <Route path='/dashboard' element={<ProtectedRoute><Dashboard/></ProtectedRoute>} />
                       <Route path='/settings' element={<ProtectedRoute><Settings/></ProtectedRoute>} />
                       <Route path='/transactions' element={<ProtectedRoute><Transactions/></ProtectedRoute>} />
                       <Route path='/contact' element={<ProtectedRoute><Contact/></ProtectedRoute>} />
                       <Route path='/profile' element={<ProtectedRoute><Profile/></ProtectedRoute>} />
                       <Route path='/account-family' element={<ProtectedRoute><AccountFamily/></ProtectedRoute>} />
                      <Route path='/mortgage-calculator' element={<ProtectedRoute><MortgageCalculator/></ProtectedRoute>} />
                    </Routes>
                    <DwFooter/>
                 </AccountContext.Provider>
               </UserContext.Provider>
           </div>
       </AuthContext.Provider>
    );
}


