import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#2c2c2c',
    },
    secondary: {
      main: '#4db6ac',
    },
    error: {
      main: red.A200,
    },
    background: {
      default: '#f5f5f5',
    },
    border: {
      default: '#e1e3e1',
    },
    title: {
      header: '#1f1f1f',
      subHeader: '#444746',
      value: '#1f1f1f'
    }
  },
});

export default theme;