import Cookies from "js-cookie";

export var appConfig = {
   // Checkout span in minutes
   defaultCheckout: 1440,
   //host: "http://localhost:8085",
   //authHost: "http://localhost:9087",

   // host: "http://192.168.1.53:8085",
   // authHost: "http://192.168.1.53:9087",

    host: "https://frugal-services-sncliebqsa-uw.a.run.app",
    authHost: "https://auth.kuriotech.com",
   parseToken: function() {
      const encoded = this.token.split(".")[1];
      return JSON.parse(atob(encoded));
   }
};

export function getTokenHeader() {
   return {"Authorization": "Bearer " + Cookies.get('token')};
}

export function clearCookies() {
   Cookies.remove("token");
   Cookies.remove("user");
}