import React, {useState, useEffect, useContext} from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import formatter from "../../utils/formatter";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {styled} from "@mui/material/styles";
import TransactionDetails from "./TransactionDetails";
import {appConfig, getTokenHeader} from "../../utils/settings";
import {UserContext} from "../../context/userContext";
import {AccountContext} from "../../context/accountContext";
import {Box, Fab, Modal} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import TransactionInput from "../TransactionInput/TransactionInput";
import AppDrawer from "../AppDrawer/AppDrawer";


const PREFIX = 'Transactions';
const classes = {
    root: `${PREFIX}-root`,
    paper: `${PREFIX}-paper`,
    timeSelection: `${PREFIX}-timeSelection`,
    floatingAddBtn: `${PREFIX}-floatingAddBtn`,
}
const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
        // display: 'flex',
        flexGrow: 1,
        display: "flex",
        paddingTop: '64px'
    },
    [`& .${classes.paper}`]: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        width: '100%'
    },
    [`& .${classes.timeSelection}`]: {
        paddingTop: '15px',
        display: 'flex',
        padding: theme.spacing(2),
    },
    [`& .${classes.floatingAddBtn}`]: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(2),
    },
}))

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function Transactions(props) {
    const serverUrl = appConfig.host;
    const {user, userLoading} = useContext(UserContext);
    const { accountCategories, categoriesLoading,
        accountUsers, accountUsersLoading,
        accountFamily, accountFamilyLoading } = useContext(AccountContext);


    const currentDate = new Date();
    const [transactionData, setTransactionData] = useState([]);
    const [month, setMonth] = useState(currentDate.getMonth() + 1);
    const [year, setYear] = useState(currentDate.getFullYear());
    const [openTransactionDetails, setOpenTransactionDetails] = React.useState(false);
    const [transactionDetailData, setTransactionDetailData] = React.useState({});
    const [loadingTransactionData, setLoadingTransactionData] = React.useState(true);

    // Transaction Input Modal
    const [openTransactionInputModal, setOpenTransactionInputModal] = React.useState(false);
    const handleOpenTransactionInputModal = () => setOpenTransactionInputModal(true);
    const handleCloseTransactionInputModal = () => setOpenTransactionInputModal(false);

    const handleTransactionDetailChanges = (transactionDetailEdits) => {
        setTransactionDetailData(transactionDetailEdits);
    };

    const handleTransactionDetailSave = () => {
        let reqHeaders = getTokenHeader();
        reqHeaders = {...reqHeaders, "Content-Type": "application/json"};
        fetch(serverUrl +'/api/v1/transactions/' + transactionDetailData.id, {
            method: 'PUT',
            headers: reqHeaders,
            body: JSON.stringify({
                userId: transactionDetailData.userId,
                accountId: user.accountId,
                transactionDate: transactionDetailData.transactionDate,
                postedDate: transactionDetailData.postedDate,
                description: transactionDetailData.description,
                category: transactionDetailData.category,
                amount: transactionDetailData.amount
            })
        }).then(function (data) {
            setOpenTransactionDetails(false);
            setLoadingTransactionData(true);

        })
           .catch(function (error) {
               console.error('Request failure: ', error);
           });
    };

    const handleCloseTransactionDetails = () => {
        setOpenTransactionDetails(false);
    };

    const handleMonthChange = e => {
        setMonth(e.target.value);
        setLoadingTransactionData(true);
    }
    const handleYearChange = e => {
        setYear(e.target.value);
        setLoadingTransactionData(true);
    }



    const handleTransactionDelete = () => {
        (async () => {
            const options = {method: "DELETE", headers: getTokenHeader()};
            const response = await fetch(serverUrl +'/api/v1/transactions/' + transactionDetailData.id, options);
            const code = await response.status;
            if(code === 204) {
                // On delete success refetch transactions
                setLoadingTransactionData(true);
            } else {
                console.error("Delete transaction failed")
            }
            setOpenTransactionDetails(false);
        })();
    }

    // need users, then categories, then transactions
    // Transaction data async load
    useEffect(() => {
        if(!accountUsersLoading && !accountFamilyLoading && loadingTransactionData) {
            (async () => {
                console.log("fetching transactions");
                const options = {method: "GET", headers: getTokenHeader()};
                const response = await fetch(serverUrl +'/api/v1/transactions/' + month + '/' + year, options);
                const transactions = await response.json();
                setTransactionData(formatter.formatTransactions(transactions));
                setLoadingTransactionData(false);
            })();
        }
    }, [month, year, accountUsers, accountFamily, loadingTransactionData]);

    // Only do this if account categories are loaded in context
    function getCategoryNameForId(categoryId) {
        if(categoriesLoading) {
            return "";
        } else {
            return accountCategories.find((category) => category.id === categoryId).category || "Unknown Category";
        }
    }

    // Only do this if account users & family are loaded in context
    function getNameForId(id) {
        if(!accountUsersLoading && !accountFamilyLoading) {
            if(id === user.userId) {
                // currently only supporting single user accounts
                return user.fname;
            } else {
                // must be a family member
                const member = accountFamily.find((familyMember) => familyMember.id === id);
                return member ? member.firstName: "Unknown User";
            }
        } else {
            return "";
        }
    }

    function isCategoryMatch(category, transactionCategoryId) {
        return category.id === transactionCategoryId;
    }


    return (
      <Root className={classes.root}>
          <AppDrawer/>
          <Paper className={classes.paper}>


              <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6} lg={6} className={classes.timeSelection}>
                      <Grid container spacing={2}>
                          <Grid item xs={6}>
                              <FormControl style={{width: "150px"}}>
                                  <InputLabel id="month-select-label">Month</InputLabel>
                                  <Select
                                     labelId="month-select-label"
                                     id="month-select"
                                     value={month}
                                     label="Month"
                                     onChange={handleMonthChange}
                                  >
                                      <MenuItem value={1}>January</MenuItem>
                                      <MenuItem value={2}>February</MenuItem>
                                      <MenuItem value={3}>March</MenuItem>
                                      <MenuItem value={4}>April</MenuItem>
                                      <MenuItem value={5}>May</MenuItem>
                                      <MenuItem value={6}>June</MenuItem>
                                      <MenuItem value={7}>July</MenuItem>
                                      <MenuItem value={8}>August</MenuItem>
                                      <MenuItem value={9}>September</MenuItem>
                                      <MenuItem value={10}>October</MenuItem>
                                      <MenuItem value={11}>November</MenuItem>
                                      <MenuItem value={12}>December</MenuItem>
                                  </Select>
                              </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                              <FormControl style={{width: "150px"}}>
                                  <InputLabel id="year-select-label">Year</InputLabel>
                                  <Select
                                     labelId="year-select-label"
                                     id="year-select"
                                     defaultValue={currentDate.getFullYear()}
                                     value={year}
                                     label="Year"
                                     onChange={handleYearChange}
                                  >
                                      <MenuItem value={currentDate.getFullYear()}>{currentDate.getFullYear()}</MenuItem>
                                      <MenuItem value={currentDate.getFullYear()-1}>{currentDate.getFullYear()-1}</MenuItem>
                                      <MenuItem value={currentDate.getFullYear()-2}>{currentDate.getFullYear()-2}</MenuItem>

                                  </Select>
                              </FormControl>
                          </Grid>
                      </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} className={classes.floatingAddBtn}>
                      <Fab variant="extended" onClick={handleOpenTransactionInputModal} color="secondary" aria-label="add">
                          <AddIcon />
                          Transaction
                      </Fab>
                  </Grid>
              </Grid>

              <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 300 }} aria-label="simple table">
                      <TableHead>
                          <TableRow>
                              <TableCell>Date</TableCell>
                              <TableCell align="left">Amount</TableCell>
                              <TableCell align="left">Person</TableCell>
                              <TableCell align="left">Category</TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                          {transactionData.map((row) => (
                              <TableRow
                                  key={row.id}
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                  onClick={() => {
                                      setTransactionDetailData(row)
                                      setOpenTransactionDetails(true)
                                  }}
                                  hover
                              >
                                  <TableCell component="th" scope="row">
                                      {formatter.getLocalizedDateFromEpochSeconds(row.transactionDate)}
                                  </TableCell>
                                  <TableCell align="left">{'$' + row.amount}</TableCell>
                                  <TableCell align="left">{getNameForId(row.userId)}</TableCell>
                                  <TableCell align="left">{getCategoryNameForId(row.category)}</TableCell>
                              </TableRow>
                          ))}
                      </TableBody>
                  </Table>
              </TableContainer>
          </Paper>
          {/*Transaction Details Modal*/}
          <TransactionDetails open={openTransactionDetails} transactionData={transactionDetailData}
                              handleClose={handleCloseTransactionDetails}
                              handleTransactionDetailChanges={handleTransactionDetailChanges}
                              handleTransactionDetailSave={handleTransactionDetailSave}
                              handleTransactionDelete={handleTransactionDelete}></TransactionDetails>

          {/*Transaction Input Modal*/}
          <TransactionInput open={openTransactionInputModal} accountId={user?.accountId} serverUrl={appConfig.host} categories={accountCategories} closeHandler={handleCloseTransactionInputModal} saveHandler={setLoadingTransactionData}/>
      </Root>
  );
}