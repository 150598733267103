const formatters = {
    formatTransactionChartData: function(rawTransactionData, totalBudget) {
        let runningTotal = totalBudget;

        let formatted = rawTransactionData.map(transaction => {
            runningTotal -= transaction.amount;
            let date = new Date(transaction.transactionDate * 1000);
            let dateString = date.getMonth() + 1 + '/' + date.getDate();
            return { ...transaction, transactionDate: dateString, runningTotal: runningTotal};
        });

        const chartjsFormatted = {
            datasets: [
               { label: 'Monthly Budget Spend', 'data': formatted}
            ], };
        return chartjsFormatted;
    },

    // Format chart for BudgetSettings
    getRandomChartColor: function() {
       const letters = '0123456789ABCDEF';
       let color = '#';
       for (let i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
       }
       return color;
    },


   getTodaysDate: function(){
      const today = new Date();

        var year = today.getFullYear();
        var month = today.getMonth()+1;
        var day = today.getDate();

        if(day < 10) {
            day = "0" + day;
        }
        if(month < 10) {
            month = "0" + month;
        }

        return year + "-" + month + "-" + day;
    },
    getDateFromEpochMs: function(epochMs){
        let utcOffsetMs = new Date().getTimezoneOffset() * 60 * 1000;
        const date = new Date(epochMs + utcOffsetMs);

        var year = date.getFullYear();
        var month = date.getMonth()+1;
        var day = date.getDate();

        if(day < 10) {
            day = "0" + day;
        }
        if(month < 10) {
            month = "0" + month;
        }

        return year + "-" + month + "-" + day;
    },
    getLocalizedCalendarDateFromEpochSeconds: function(epochSeconds){
        let utcOffsetMs = new Date().getTimezoneOffset() * 60 * 1000;
        const epochMs = epochSeconds * 1000 + utcOffsetMs;
        const date = new Date(epochMs);

        var year = date.getFullYear();
        var month = date.getMonth()+1;
        var day = date.getDate();

        if(day < 10) {
            day = "0" + day;
        }
        if(month < 10) {
            month = "0" + month;
        }

        return year + "-" + month + "-" + day;
    },
    formatTransactions: function(rawTransactions) {
        rawTransactions.sort((a,b) => b.transactionDate - a.transactionDate);
        let formatted = rawTransactions.map(transaction => {
            return {
                postedDate: transaction.postedDate,
                transactionDate: transaction.transactionDate,
                amount: transaction.amount,
                category: transaction.category,
                description: transaction.description,
                id: transaction.id,
                userId: transaction.userId,
            };
        });
        return formatted;
    },
    getLocalizedDateFromEpochSeconds: function(epochSeconds) {
        const epochMs = epochSeconds * 1000;
        let utcOffsetMs = new Date().getTimezoneOffset() * 60 * 1000;
        const date = new Date(epochMs + utcOffsetMs);
        return (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
    }

}

export default formatters;