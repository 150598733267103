import React, {useContext, useEffect, useState} from "react";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import TransactionInput from "../TransactionInput/TransactionInput";
import { styled } from '@mui/material/styles'
import {Line, Doughnut, Bar} from 'react-chartjs-2';
import formatters from "../../utils/formatter";
import {appConfig} from "../../utils/settings";

import {
   Chart as ChartJS,
   CategoryScale,
   LinearScale,
   PointElement,
   LineElement,
   Title,
   Tooltip,
   Legend,
   TimeScale, ArcElement, BarElement,
} from 'chart.js';
import {Box, Fab, Modal} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {getTokenHeader} from "../../utils/settings";
import {AccountContext} from "../../context/accountContext";
import {UserContext} from "../../context/userContext";
import Typography from "@mui/material/Typography";
import DateYearSelection from "./DateYearSelection";
import BudgetStatusBanner from "../shared/BudgetStatusBanner";
import AppDrawer from "../AppDrawer/AppDrawer";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    ArcElement,
    BarElement
);

const PREFIX = 'Dashboard';
const classes = {
    root: `${PREFIX}-root`,
    paper: `${PREFIX}-paper`,
    lineGraph: `${PREFIX}-timeSelection`,
    floatingAddBtn: `${PREFIX}-floatingAddBtn`,
}
const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
       display: "flex",
       paddingTop: '64px'
    },
    [`& .${classes.paper}`]: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        width: '100%'
    },
    [`& .${classes.lineGraph}`]: {
        minHeight: "200px"
    },
   [`& .${classes.floatingAddBtn}`]: {
      padding: theme.spacing(2),
   },
}))

const modalStyle = {
   minWidth: '300px',
   position: 'absolute',
   top: '50%',
   left: '50%',
   transform: 'translate(-50%, -50%)',
   bgcolor: 'background.paper',
   border: '2px solid #000',
   boxShadow: 24,
   p: 4,
};

export default function Dashboard(props) {
  const {user, userLoading} = useContext(UserContext);
  const {accountCategories, loadingAccountCategories} = useContext(AccountContext);

  const [budgetTotal, setBudgetTotal] = React.useState(0);
  const currentDate = new Date();
  const [budgets, setBudgets] = React.useState([]);
  const [budgetsLoading, setBudgetsLoading] = React.useState(false);
  const [transactionDataLoading, setTransactionDataLoading] = useState(true);
  const [transactionData, setTransactionData] = useState([]);
  const [month, setMonth] = useState(currentDate.getMonth() + 1);
  const [year, setYear] = useState(currentDate.getFullYear());

  const [donutGraphDatasets, setDonutGraphDatasets] = useState([]);
  const [loadingCategoryBudgetDatasets, setLoadingCategoryBudgetDatasets] = useState(true);
  const [donutGraphComponents, setDonutGraphComponents] = useState(null);

  const [spendAcrossCategoriesDataset, setSpendAcrossCategoriesDataset] = useState({datasets: []});


  // Transaction Input Modal
   const [openTransactionInputModal, setOpenTransactionInputModal] = React.useState(false);
   const handleOpenTransactionInputModal = () => setOpenTransactionInputModal(true);
   const handleCloseTransactionInputModal = () => setOpenTransactionInputModal(false);

   const handleMonthChange = e => { setMonth(e.target.value)}
   const handleYearChange = e => setYear(e.target.value);


   const lineChartOptions = {
      responsive: true,
      borderColor: 'rgb(75, 192, 192)',
      plugins: {
         legend: {
            display: false
         },
        title: {
           display: true,
           text: 'Monthly total spend',
        },
      },
      parsing: {
         xAxisKey: 'transactionDate',
         yAxisKey: 'runningTotal'
      },
      scales: {
         y: {
            min: 0,
            max: budgetTotal
        },
      }
   };

  const spendingByCategoryOptions = {
    plugins: {
      legend: {display: false},
      title: {
        display: true,
        text: 'Spend per Category',
      },
    }
  }


   useEffect(() => {
      setTransactionDataLoading(false);
   }, [transactionData]);

  // Transaction data async load
   useEffect(() => {
      if(!userLoading) {
         (async () => {
            const options = {method: "GET", headers: getTokenHeader()};
            const response = await fetch(appConfig.host +'/api/v1/transactions/' + month + '/' + year, options);
            const transactions = await response.json();
            setTransactionData(transactions);
         })();
      }
  }, [month, year, userLoading, transactionDataLoading]);

   // Category Budgets async load to populate total budget for graphs
   useEffect(() => {
      if(!userLoading) {
         (async () => {
            const options = {method: "GET", headers: getTokenHeader()};
            const response = await fetch(appConfig.host +'/api/v1/category/budgets', options);
            const budgets = await response.json();
            setBudgetsLoading(false);
            setBudgets(budgets);
            setBudgetTotal(calculateBudgetSum(budgets));
         })();
      }
   }, [budgetsLoading, loadingAccountCategories, userLoading]);

   useEffect(() => {
      if(accountCategories.length > 0 && budgets.length > 0 && transactionData.length > 0) {
        const chartData = generateDonutChartData(transactionData, accountCategories, calculateBudgetSum(budgets));
        setSpendAcrossCategoriesDataset(chartData);

      }
   }, [transactionData, accountCategories, budgets]);

   // Format donut graph data
   // Transactions are optional but categories and budgets required for render
   useEffect(() => {
      if(accountCategories.length > 0 && budgets.length > 0) {
         const formattedDataSets = createDonutGraphDatasets(transactionData, accountCategories, budgets)
         setDonutGraphDatasets(formattedDataSets);
         setLoadingCategoryBudgetDatasets(false);
      }
   }, [transactionData, accountCategories, budgets]);

   // Dynamically build chart components per budget category
   useEffect(() => {
      if(!loadingCategoryBudgetDatasets) {
         const budgetGraphs = donutGraphDatasets.map((dataset, index) => (
            <Grid item sm={3} xs={6} key={index}>
               <Grid><Typography>{dataset.category}</Typography></Grid>
               <Grid><Doughnut data={dataset} /></Grid>
            </Grid>
         ));
         setDonutGraphComponents(budgetGraphs);
      }
   }, [donutGraphDatasets, loadingCategoryBudgetDatasets]);

   function calculateBudgetSum(budgets){
      const sum = budgets.reduce((accumulator, currentValue) => {
         return accumulator + currentValue.categoryBudget
      }, 0)
      return sum;
   }

  function calculateTransactionsSum(transactionData) {
     const sum = transactionData.reduce((accumulator, currentValue) => {
       return accumulator + currentValue.amount
     }, 0)
    return sum;
  }

   function filterAndFormatDataForDonutGraph(categoryBudgetId, transactions, categories, categoryBudgets) {
      // Filter transactions based on the provided category budget ID
      const filteredTransactions = transactions.filter((transaction) => {
         const category = categories.find((cat) => cat.id === transaction.category);
         const categoryBudget = categoryBudgets.find((budget) => budget.id === categoryBudgetId);

         return category !== undefined && categoryBudget !== undefined && category.id === categoryBudget.categoryId;
      });


      // Calculate total spending for the selected category
      const totalSpending = filteredTransactions.reduce((sum, transaction) => sum + transaction.amount, 0);

      // Calculate the remaining budget for the selected category
      const categoryBudget = categoryBudgets.find((budget) => budget.id === categoryBudgetId);
      const remainingBudget = categoryBudget ? categoryBudget.categoryBudget - totalSpending : 0;

      const optCategoryName = categories.find((category) => category.id === categoryBudget.categoryId);
      const categoryName = optCategoryName === undefined ? "unknown": optCategoryName.category;

      const spentColor = '#ef9a9a';
      const spentColorHover = '#e57373';

      const remainingPositiveBalanceColor = '#80cbc4';
      const remainingPositiveBalanceColorHover = '#4db6ac';

      const remainingNegativeBalanceColor = '#f44336';
      const remainingNegativeBalanceColorHover = '#b71c1c';

      const remainingBudgetColor = remainingBudget > 0 ? remainingPositiveBalanceColor: remainingNegativeBalanceColor;
      const remainingBudgetColorHover = remainingBudget > 0 ? remainingPositiveBalanceColorHover: remainingNegativeBalanceColorHover;
      const remainingOrOverageLabel = remainingBudget > 0 ? 'Remaining': 'Overage';

      // Whether this is an overage negative number or positive balance this should always be displayed as positive.
      // The label overage or remaining will indicate pos/neg
      const alwaysPositiveRemainingBudget = Math.abs(remainingBudget);

      // Format data for Chart.js donut graph
      const chartData = {
         category: categoryName,
         labels: ['Spent', remainingOrOverageLabel],
         datasets: [
            {
               data: [totalSpending, alwaysPositiveRemainingBudget],
               backgroundColor: ['#ef9a9a', remainingBudgetColor],
               hoverBackgroundColor: ['#e57373', remainingBudgetColorHover],
            },
         ],
      };
      return chartData;
   }

   function createDonutGraphDatasets(transactions, categories, categoryBudgets) {
      const datasets = [];

      // Iterate through each category budget
      categoryBudgets.forEach((budget) => {
         const categoryBudgetId = budget.id;

         // Filter and format data for the current category budget
         const donutChartData = filterAndFormatDataForDonutGraph(
            categoryBudgetId,
            transactions,
            categories,
            categoryBudgets
         );

         // Add the dataset to the datasets array
         datasets.push(donutChartData);
      });
      return datasets;
   }


   return (
      <Root className={classes.root}>
        <AppDrawer/>
         <Paper className={classes.paper}>
            {/*Transaction Input Modal*/}
            <TransactionInput open={openTransactionInputModal} accountId={user?.accountId} serverUrl={appConfig.host} categories={accountCategories} closeHandler={handleCloseTransactionInputModal} saveHandler={setTransactionDataLoading}/>

             <Grid container alignItems="center">
                <Grid item lg={6} md={6} sm={6}>
                   <DateYearSelection month={month} year={year} handleMonthChange={handleMonthChange} handleYearChange={handleYearChange}/>
                </Grid>

                <Grid item className={classes.floatingAddBtn} mdOffset="auto">
                   <Fab variant="extended" onClick={handleOpenTransactionInputModal} color="secondary" aria-label="add">
                      <AddIcon />
                      Transaction
                   </Fab>
                </Grid>
             </Grid>

            <Grid container>
               <BudgetStatusBanner totalBudget={calculateBudgetSum(budgets)} totalTransactions={calculateTransactionsSum(transactionData)}></BudgetStatusBanner>
            </Grid>

            <Grid container spacing={2}>
               <Grid item sm={8} xs={12}>
                  <Paper className={classes.lineGraph}>
                     {transactionDataLoading ? null: (
                        <Line options={lineChartOptions}
                              data= { formatters.formatTransactionChartData(transactionData, budgetTotal)}
                        />
                     )}
                  </Paper>
               </Grid>
               <Grid item sm={4} xs={12}>
                  <Paper style={{paddingBottom: "8px"}}>
                    <Doughnut data={spendAcrossCategoriesDataset} options={spendingByCategoryOptions}/>
                  </Paper>
               </Grid>
            </Grid>
            <Grid container style={{justifyContent: "center", paddingTop: "25px", paddingBottom: "15px"}}>
              <Grid item><Typography variant="h5">Category Budgets</Typography></Grid>

            </Grid>
            <Grid container xs={12}>
               {donutGraphComponents}
            </Grid>
            <div style={{ padding: 15}}></div>
         </Paper>
      </Root>
    );

  function generateDonutChartData(transactions, accountCategories, totalBudget) {
    // Calculate total spend per category
    const categoryTotals = {};
    transactions.forEach(transaction => {
      const categoryId = transaction.category;
      const transactionAmount = transaction.amount;

      if (categoryTotals[categoryId] === undefined) {
        categoryTotals[categoryId] = 0;
      }

      categoryTotals[categoryId] += transactionAmount;
    });

    // Prepare data for Chart.js
    const chartData = {
      labels: [],
      datasets: [{
        data: [],
        backgroundColor: [], // You can customize colors here
      }]
    };

    // Populate chartData with category names, spending, and percentages
    Object.keys(categoryTotals).forEach(categoryId => {
      const categoryName = accountCategories.find(category => category.id === categoryId).category;
      const spending = categoryTotals[categoryId];
      //const percentage = (spending / totalBudget) * 100;

      chartData.labels.push(categoryName);
      chartData.datasets[0].data.push(spending);
      chartData.datasets[0].backgroundColor.push(formatters.getRandomChartColor()); // Function to generate random colors
    });
    console.log(chartData);
    return chartData;
  }


}

// Date js and java conundrum
// Currently storing data @ 12:00am UTC to db.
// When saving transaction i get epoch millis of 12am from date.getTime
// Store instant to db.
// get local utc offset in millis
// add that to utc instant millis and convert back to js date



// Chart options
//Donut: proportion of spending by category
//Stacked bar: category spending within overall budget
// Header spent/total - %left




