import React, {useContext, useEffect, useState} from 'react';

import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import {Box} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {UserContext} from "../../context/userContext";
import {AccountContext} from "../../context/accountContext";
import { IconButton } from '@mui/material';
import TextField from "@mui/material/TextField";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import formatters from "../../utils/formatter";
import Button from "@mui/material/Button";
import DeleteIcon from '@mui/icons-material/Delete';
import {appConfig, getTokenHeader} from "../../utils/settings";

const PREFIX = 'Settings';
const classes = {
   root: `${PREFIX}-root`,
   paper: `${PREFIX}-paper`,
}
const Root = styled('div')(({ theme }) => ({
   [`&.${classes.root}`]: {
   },
   [`& .${classes.paper}`]: {
      color: theme.palette.text.secondary,
      width: '100%'
   }
}))


export default function CustomCategories(props) {
   const serverUrl = appConfig.host;
   const {user, userLoading} = useContext(UserContext);
   const {accountCategories, loadingCategories, setLoadingAccountCategories} = useContext(AccountContext);
   const [customCategories, setCustomCategories] = React.useState([]);

   const [newCategory, setNewCategory] = React.useState('');
   const [newCategoryAvailable, setNewCategoryAvailable] = React.useState(true);
   const [newCategoryLabel, setNewCategoryLabel] = React.useState('Create category');
   const [newCategoryValid, setNewCategoryValid] = React.useState(true);
   const [newCategoryAttemptedSave, setNewCategoryAttemptedSave] = React.useState(false);
   const handleNewCategoryChange = e => {
      setNewCategory(e.target.value);
   }

   // Filter custom categories out of all categories
   useEffect(() => {
      if(accountCategories.length > 0) {
         console.log(accountCategories);
         // filter out default ones
         const filteredCustomCategories = accountCategories.filter((category) => category.accountId !== null);
         console.log(filteredCustomCategories);
         setCustomCategories(filteredCustomCategories);
      }
   }, [accountCategories]);


   // New Category
   useEffect(() => {
      if(newCategory.length > 3) {
         console.log('checking for dup category');
         checkIfNewCategoryIsDuplicate();
      }
   }, [newCategory]);

   useEffect(() => {
      if(newCategory.length > 3 || newCategory.length == 0) {
         console.log('valid')
         setNewCategoryValid(true);
      } else {
         console.log('invalid')
         setNewCategoryValid(false);
         setNewCategoryLabel("3 character min")
      }
   }, [newCategory]);

   function checkIfNewCategoryIsDuplicate() {
      console.log(newCategory);

      const isValid = true;
      if(isValid) {
         (async () => {
            const options = {method: "GET", headers: getTokenHeader()};
            const response = await fetch(serverUrl +'/api/v1/categories/check?category=' + newCategory, options);
            const categoryAvailableStatus = await response.json();
            console.log('IsAvailable:' + categoryAvailableStatus);
            setNewCategoryAvailable(categoryAvailableStatus);
            if(categoryAvailableStatus) {
               setNewCategoryLabel("Create category")
            } else {
               setNewCategoryLabel("Category exists");
            }
         })();

      } else {
         //handleAlertOpen();
      }
   }


   function createCustomCategory() {
      console.log(newCategory);

      // keep this a static check so the input doesn't start off red
      const isValid = newCategoryIsValid();
      if(isValid) {
         let reqHeaders = getTokenHeader();
         reqHeaders = {...reqHeaders, "Content-Type": "application/json"};
         fetch(serverUrl + '/api/v1/categories', {
            method: 'POST',
            headers: reqHeaders,
            body: JSON.stringify({
               accountId: user.accountId,
               category: newCategory
            })
         }).then(function (data) {
            // trigger reload of accountContext categories
            setLoadingAccountCategories(true);
            // reset flags
            setNewCategoryAttemptedSave(false)
            setNewCategory('');
            setNewCategoryLabel('Create category');
            setNewCategoryValid(true);
         }).catch(function (error) {
            console.error('Request failure: ', error);
         });
      } else {
         setNewCategoryAttemptedSave(true);
         // If its not valid Toast
         //handleAlertOpen();
         // min 3 chars or already exists error
      }
   }

   function deleteCustomCategory(id) {
      // If you delete a category you also need to update any transactions with that category to the other category id.
      (async () => {
         const options = {method: "DELETE", headers: getTokenHeader()};
         const response = await fetch(serverUrl +'/api/v1/categories/' + id, options);
         setLoadingAccountCategories(true);
      })();
   }

   function newCategoryIsValid(){
      if (newCategory.length > 3 && newCategoryAvailable) {
         return true;
      } else {
         setNewCategoryAttemptedSave(true);
         return false;
      }
   }

   return (
      <Root className={classes.root}>
         <Grid container spacing={2}>
            <Grid xs={12} md={4}>
               <Box display={'flex'} alignItems={'flex-end'}>
                  <Box><TextField id="outlined-basic" label={newCategoryLabel} variant="outlined" value={newCategory} onChange={handleNewCategoryChange} error={!newCategoryAvailable || !newCategoryValid}/></Box>
                  <Box sx={{paddingBottom: "7px", paddingLeft: "7px"}}>
                     <Button variant="contained" color="primary" onClick={createCustomCategory}>Add</Button>
                  </Box>
               </Box>



            </Grid>
            <Grid xs={12} md={4}>
               <TableContainer component={Paper}>
                  <Table sx={{ width: '100%' }} aria-label="simple table">
                     <TableHead>
                        <TableRow>
                           <TableCell>Custom Categories</TableCell>
                           <TableCell></TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {customCategories.map((category) => (
                           <TableRow
                              key={category.id}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                           >
                              <TableCell component="th" scope="row">
                                 {category.category}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                 <IconButton aria-label="delete" onClick={() => deleteCustomCategory(category.id)}>
                                    <DeleteIcon />
                                 </IconButton>
                              </TableCell>
                           </TableRow>
                        ))}
                     </TableBody>
                  </Table>
               </TableContainer>
            </Grid>
            <Grid xs={12} md={4}>
               <TableContainer component={Paper}>
                  <Table sx={{ width: '100%' }} aria-label="simple table">
                     <TableHead>
                        <TableRow>
                           <TableCell>Default Categories</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {accountCategories.filter(category => category.accountId == null).map((category) => (
                           <TableRow
                              key={category.id}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                           >
                              <TableCell component="th" scope="row">
                                 {category.category}
                              </TableCell>
                           </TableRow>
                        ))}
                     </TableBody>
                  </Table>
               </TableContainer>
            </Grid>
         </Grid>

      </Root>
   );
}

