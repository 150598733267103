import React, {useContext, useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import OutlinedInput from "@mui/material/OutlinedInput";
import {Box, InputAdornment, Snackbar} from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import {appConfig, getTokenHeader} from "../../utils/settings";
import Alert from '@mui/material/Alert';
import {UserContext} from "../../context/userContext";
import {AccountContext} from "../../context/accountContext";
import CategoryBudgetDetails from "./CategoryBudgetDetails";
import {Doughnut} from "react-chartjs-2";
import formatters from "../../utils/formatter";

const PREFIX = 'Settings';
const classes = {
   root: `${PREFIX}-root`,
   paper: `${PREFIX}-paper`,
   budgetGraphContainer: `${PREFIX}-budgetGraphContainer`,
   totalContainer: `${PREFIX}-total`,
   header: `${PREFIX}-header`,
   newBudget: `${PREFIX}-newBudget`,
   categoriesTable: `${PREFIX}-categories`,
   inputs: `${PREFIX}-inputs`,
}
const Root = styled('div')(({ theme }) => ({
   [`&.${classes.root}`]: {
   },
   [`& .${classes.paper}`]: {
      color: theme.palette.text.secondary,
      width: '100%'
   },
   [`& .${classes.budgetGraphContainer}`]: {
      display: 'flex',
      justifyContent: 'center'
   },
   [`& .${classes.totalContainer}`]: {
      color: theme.palette.text.secondary,
      width: '100%',
      padding: '15px'
   },
   [`& .${classes.header}`]: {
      color: theme.palette.text.secondary,
      width: '100%',
      display: 'flex',
      justifyContent: 'center'
   },
   [`& .${classes.newBudget}`]: {
      display: 'flex',
      justifyContent: 'flex-start',
      paddingLeft: '16px'
   },
   [`& .${classes.categoriesTable}`]: {
      color: theme.palette.text.secondary,
      width: '100%',
      marginTop: '50px',
      padding: '15px',
   },
   [`& .${classes.inputs}`]: {
      paddingBottom: '15px',
   },
}))


export default function Settings(props) {
   const serverUrl = appConfig.host;
   const {user, userLoading} = useContext(UserContext);
   const {accountCategories, loadingAccountCategories} = useContext(AccountContext);
   const [categoryNameAndAmountMap, setCategoryNameAndAmountMap] = React.useState(null);
   const [categoryBudgetGraphData, setCategoryBudgetGraphData] = React.useState({datasets: []});

   const [budgetTotal, setBudgetTotal] = React.useState(0);
   const [budgets, setBudgets] = React.useState([]);
   const [budgetsLoading, setBudgetsLoading] = React.useState(false);

   const[category, setCategory] = useState('');
   const[categoryAmount, setCategoryAmount] = useState(0);

   const [alertOpen, setAlertOpen] = React.useState(false);
   const [openCategoryBudgetDetails, setOpenCategoryBudgetDetails] = React.useState(false);
   const [categoryBudgetDetailData, setCategoryBudgetDetailData] = React.useState({});

   const handleNewCategoryNameChange = (e, v) => {
      setCategory(v);
   };
   const handleNewCategoryAmountChange = (e) => {
      const amount = Number(e.target.value);
      setCategoryAmount(amount);

   };

   const handleAlertOpen = () => {
      console.error("Invalid Category Budget, Cannot save");
      setAlertOpen(true);

      setTimeout(handleAlertClose, 3000);
   }

   const handleAlertClose = () => {
      setAlertOpen(false);
   }

   const handleCategoryBudgetDetailsClose = () => {
      setOpenCategoryBudgetDetails(false);
   }
   const handleCategoryBudgetDetailChanges = (categoryBudgetEdits) => {
      setCategoryBudgetDetailData(categoryBudgetEdits);
   }
   const handleCategoryBudgetDetailUpdate = () => {
      const currentCategoryBudget = categoryBudgetDetailData;
      const categoryBudget = {"accountId": currentCategoryBudget.accountId, "categoryId": currentCategoryBudget.categoryId, "categoryBudget": currentCategoryBudget.categoryBudget, "id": currentCategoryBudget.id}
      const categoryBody = JSON.stringify(categoryBudget);

      (async () => {
         let reqHeaders = getTokenHeader();
         reqHeaders = {...reqHeaders,  "Content-Type": "application/json"};
         const options = {method: "PUT", headers: reqHeaders, body: categoryBody};
         const response = await fetch(serverUrl +'/api/v1/category/budgets/' + categoryBudgetDetailData.id, options);
         const code = await response.status;
         if(code === 200) {
            // On delete success refetch transactions
            setBudgetsLoading(true);
         } else {
            console.error("Update transaction failed")
         }
         setOpenCategoryBudgetDetails(false);
      })();
   }

   function createCategoryBudget() {
      const categoryBudget = {"accountId": user.accountId, "categoryId": category.id, "categoryBudget": categoryAmount}
      const categoryBody = JSON.stringify(categoryBudget);

      const isValid = isValidCategoryBudget(categoryBudget);
      if(isValid) {
         let reqHeaders = getTokenHeader();
         reqHeaders = {...reqHeaders, "Content-Type": "application/json"};
         fetch(serverUrl + '/api/v1/category/budgets', {
            method: 'POST',
            headers: reqHeaders,
            body: categoryBody
         }).then(function (data) {
            setBudgetsLoading(true);
            setCategory('');
            setCategoryAmount(0);
         }).catch(function (error) {
            console.error('Request failure: ', error);
         });
      } else {
         handleAlertOpen();
      }
   }


   const handleCategoryBudgetDetailDelete = () => {
      (async () => {
         const options = {method: "DELETE", headers: getTokenHeader()};
         const response = await fetch(serverUrl +'/api/v1/category/budgets/' + categoryBudgetDetailData.id, options);
         const code = await response.status;
         if(code === 202) {
            // On delete success refetch transactions
            setBudgetsLoading(true);
         } else {
            console.error("Delete transaction failed")
         }
         setOpenCategoryBudgetDetails(false);
      })();
   }


   // Category Budgets async load
   useEffect(() => {
      if(!userLoading) {
         (async () => {
            const options = {method: "GET", headers: getTokenHeader()};
            const response = await fetch(serverUrl +'/api/v1/category/budgets', options);
            const budgets = await response.json();
            setBudgetsLoading(false);
            setBudgets(budgets);
            setBudgetTotal(calculateBudgetSum(budgets));
         })();
      }
   }, [budgetsLoading, loadingAccountCategories, userLoading]);

   // Category id to name flat map
   useEffect(() => {
      // don't bother rendering if we haven't loaded initially
      if(!loadingAccountCategories && !budgetsLoading) {
         (async () => {
            let categoriesMap = new Map();
             accountCategories.forEach((categoryEntry) => {
                categoriesMap.set(categoryEntry.id, categoryEntry.category);
             });
            let nameToAmountMap = new Map();

            budgets.forEach(budget => {
               nameToAmountMap.set(categoriesMap.get(budget.categoryId), budget.categoryBudget);
            });
            setCategoryNameAndAmountMap(nameToAmountMap);
         })();
      }
      // We can only render once budgets and accountCategories are fully populated
   }, [budgets, accountCategories]);

   // Populate the Budget by Category graph
   useEffect(() => {
      if(categoryNameAndAmountMap != null) {
         // Prepare data for Chart.js
         const chartData = {
            labels: [],
            datasets: [{
               data: [],
               backgroundColor: [], // You can customize colors here
            }]
         };

         categoryNameAndAmountMap.forEach((value, key)=> {
            chartData.labels.push(key);
            chartData.datasets[0].data.push(value);
            chartData.datasets[0].backgroundColor.push(formatters.getRandomChartColor());
         });

         setCategoryBudgetGraphData(chartData);
      }
   }, [categoryNameAndAmountMap]);


   const budgetByCategoryOptions = {
      plugins: {
         legend: {display: false},
         title: {
            display: true,
            text: 'Budget by category',
         },
      }
   }

   const categoryToBudgetData = {
      datasets: [
         { label: 'Budget by Category', 'data': [100, 200]}
      ], };

   function calculateBudgetSum(budgets){
      const sum = budgets.reduce((accumulator, currentValue) => {
         return accumulator + currentValue.categoryBudget
      }, 0)
      return sum;
   }


   function getNameForCategory(categoryId) {
      if(accountCategories.length) {
          let foundCategory = accountCategories.find((localCategory) => localCategory.id === categoryId);
          return foundCategory ? foundCategory.category: 'unknown';
      } else {
         return categoryId;
      }
   }

   function isValidCategoryBudget(categoryBudget) {
      return isValidCategory(categoryBudget.categoryId) && isValidCategoryAmount(categoryBudget.categoryBudget);
   }

   function isValidCategory(categoryId) {
      return categoryId !== undefined;
   }
   function isValidCategoryAmount(categoryAmount) {
      return categoryAmount >= 0;
   }

   // Only render this modal if we've loaded budget detail data
   function renderDetailsModal() {
      return Object.keys(categoryBudgetDetailData).length !== 0 ? (
        <CategoryBudgetDetails open={openCategoryBudgetDetails} categoryBudgetDetailData={categoryBudgetDetailData}
                               handleClose={handleCategoryBudgetDetailsClose}
                               handleCategoryBudgetDetailChanges={handleCategoryBudgetDetailChanges}
                               handleCategoryBudgetDetailSave={handleCategoryBudgetDetailUpdate}
                               handleCategoryBudgetDelete={handleCategoryBudgetDetailDelete}
                               categoryName={getNameForCategory(categoryBudgetDetailData.categoryId)}>
        </CategoryBudgetDetails>
      ): null;
   }

   return (
      <Root className={classes.root}>
         <Snackbar open={alertOpen}>
            <Alert severity="error" onClose={() => handleAlertClose()}>Failed to save Category Budget — invalid options supplied!</Alert>
         </Snackbar>

         <Grid container>
            <Grid item xs={12} className={classes.header}>
               <Typography variant="h4"> Create a budget </Typography>
            </Grid>
         </Grid>

         <Grid container spacing={2} style={{paddingTop: '25px'}} justify="flex-start">
            <Grid item xs={12} md={4}>
               <Paper style={{paddingBottom: "8px", maxHeight: '200px'}} className={classes.budgetGraphContainer}>
                  <Doughnut data={categoryBudgetGraphData} options={budgetByCategoryOptions}/>
               </Paper>
            </Grid>

            <Grid item xs={12} md={8}>
               <Paper elevation={3} className={classes.totalContainer}>
                  <Typography variant="h6" style={{marginLeft: "16px"}}> Monthly Budget Total: ${budgetTotal}</Typography>
               </Paper>

               {/*The new category input*/}
               <Grid container spacing={2} style={{paddingTop: '50px'}}>
                  <Grid container className={classes.newBudget} spacing={2}>
                     <Grid item xs={12} md={5}>
                        <Typography variant='h6' className={classes.inputs}> Category</Typography>
                        <Autocomplete
                           disablePortal
                           id="category-budget-input"
                           getOptionLabel={option => option ? option.category: ""}
                           options={accountCategories}
                           sx={{width: "100%" }}
                           onChange={handleNewCategoryNameChange}
                           value={category}
                           renderInput={(params) => <TextField {...params} label="New Category" />}
                        />
                     </Grid>
                     <Grid item xs={9} md={4}>
                        <Typography variant='h6' className={classes.inputs}> Budget </Typography>
                        <FormControl>
                           <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                           <OutlinedInput
                              id="outlined-adornment-amount"
                              startAdornment={<InputAdornment position="start">$</InputAdornment>}
                              label="Amount"
                              onChange={handleNewCategoryAmountChange}
                              sx={{width: "100%" }}
                              value={categoryAmount}
                           />
                        </FormControl>
                     </Grid>
                     <Grid item xs={3} sx={{display: 'flex', justifyContent:{xs: 'flex-end', md: 'flex-start'}, alignItems: 'flex-end', paddingBottom: '10px'}}>
                        <Box>
                           <Button variant="contained" color="primary" onClick={createCategoryBudget}>Add</Button>
                        </Box>
                     </Grid>
                  </Grid>
               </Grid>
            </Grid>
         </Grid>



         <TableContainer component={Paper} className={classes.categoriesTable}>
            <Table sx={{ minWidth: 250 }} aria-label="simple table">
               <TableHead>
                  <TableRow>
                     <TableCell align="left">Category</TableCell>
                     <TableCell align="left">Amount</TableCell>

                  </TableRow>
               </TableHead>
               <TableBody>
                  {budgets.map((row) => (
                     <TableRow
                        key={row.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        hover
                        onClick={() => {
                           setCategoryBudgetDetailData(row)
                           setOpenCategoryBudgetDetails(true)
                        }}
                     >
                        <TableCell component="th" scope="row">{getNameForCategory(row.categoryId)}</TableCell>
                        <TableCell align="left">{'$' + row.categoryBudget}</TableCell>
                     </TableRow>
                  ))}
               </TableBody>
            </Table>
         </TableContainer>

         {/*Category Budget Details Modal*/}
         {renderDetailsModal()}
      </Root>
   );
}