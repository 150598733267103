import React, {useState, useEffect, useContext} from 'react';
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { makeStyles } from '@mui/styles';
import AppDrawer from "../AppDrawer/AppDrawer";
import {UserContext} from "../../context/userContext";
import {AccountContext} from "../../context/accountContext";
import {Box, Fab, IconButton} from "@mui/material";
import {appConfig, getTokenHeader} from "../../utils/settings";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import AddIcon from "@mui/icons-material/Add";
import AddFamily from "./AddFamily";
import DeleteIcon from "@mui/icons-material/Delete";

const PREFIX = 'AccountFamily';
const classes = {
   root: `${PREFIX}-root`,
   paper: `${PREFIX}-paper`,
   titleRow: `${PREFIX}-titleRow`,
   itemRow: `${PREFIX}-itemRow`,
}
const Root = styled('div')(({ theme }) => ({
   [`&.${classes.root}`]: {
      display: "flex",
      paddingTop: '64px'
   },
   [`& .${classes.paper}`]: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      width: '100%',
   },

   [`& .${classes.titleRow}`]: {
      display: 'flex',
      justifyContent: 'flex-start'
   },
   [`& .${classes.itemRow}`]: {
      paddingLeft: '10px',
   },
}))

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
   accountInfoContainer: {
      padding: theme.spacing(3),
   },
   floatingAddBtn: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: theme.spacing(2),
   }
}));


export default function AccountFamily(props) {
   // Use the styles
   const classes2 = useStyles();
   const serverUrl = appConfig.host;
   const {user, userLoading} = useContext(UserContext);
   const {accountUsers, accountUsersLoading} = useContext(AccountContext);
   const {accountFamily} = useContext(AccountContext);
   const {accountFamilyLoading, setAccountFamilyLoading} = useContext(AccountContext);

   const [openNewFamilyMemberModal, setOpenNewFamilyMemberModal] = useState(false);
   const handleOpenNewFamilyMemberModal = () => setOpenNewFamilyMemberModal(true);
   const handleCloseNewFamilyMemberModal = () => {
      setOpenNewFamilyMemberModal(false);
      setAccountFamilyLoading(true);
   }

   useEffect(() => {
   }, [accountUsersLoading]);

   useEffect(() => {
   }, [accountFamily, accountFamilyLoading]);

   function deleteFamilyMember(id) {
      // If you delete a family member you also need to update any transactions using that member id.
      (async () => {
         const options = {method: "DELETE", headers: getTokenHeader()};
         const response = await fetch(serverUrl +'/api/v1/family/' + id, options);
         setAccountFamilyLoading(true);
      })();
   }


   return (
      <Root className={classes.root}>
         <AppDrawer/>
         <Paper className={classes.paper}>
            <div style={{display: 'flex', justifyContent: 'center', paddingTop: '50px'}}>
               <Box border={1} borderRadius={2} borderColor={'border.default'} sx={{padding: '15px', width: '100%'}}>
                  <Grid container sx={{display: 'flex', alignItems: 'center'}}>
                     <Grid item xs={12} className={classes.titleRow}>
                        <Typography variant="h5" sx={{fontWeight: '400', color: 'title.header'}} gutterBottom>
                           Account Owner
                        </Typography>
                     </Grid>
                  </Grid>
                  <TableContainer component={Paper}>
                     <Table>
                        <TableHead>
                           <TableCell>First Name</TableCell>
                           <TableCell align="right">Last Name</TableCell>
                           <TableCell align="right">Role</TableCell>
                        </TableHead>
                        <TableBody>

                        </TableBody>
                           {accountUsers.map((row) => (
                              <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                 <TableCell component="th" scope="row">{row.firstName}</TableCell>
                                 <TableCell align="right">{row.lastName}</TableCell>
                                 <TableCell align="right">{row.role}</TableCell>
                              </TableRow>
                           ))}
                     </Table>
                  </TableContainer>
               </Box>
            </div>
            <Grid container spacing={2} sx={{paddingTop: '15px', justifyContent: 'flex-end'}}>
               <Grid item xs={12} className={classes.floatingAddBtn}>
                  <Fab variant="extended" onClick={handleOpenNewFamilyMemberModal} color="secondary" aria-label="add">
                     <AddIcon />
                     Family Member
                  </Fab>
               </Grid>
            </Grid>

            <div style={{display: 'flex', justifyContent: 'center', paddingTop: '15px'}}>
               <Box border={1} borderRadius={2} borderColor={'border.default'} sx={{padding: '15px', width: '100%'}}>
                  <Grid container sx={{display: 'flex', alignItems: 'center'}}>
                     <Grid item xs={12} className={classes.titleRow}>
                        <Typography variant="h5" sx={{fontWeight: '400', color: 'title.header'}} gutterBottom>
                           Family Members
                        </Typography>
                     </Grid>
                  </Grid>
                  <TableContainer component={Paper}>
                     <Table>
                        <TableHead>
                           <TableCell>First Name</TableCell>
                           <TableCell align="right">Role</TableCell>
                           <TableCell></TableCell>
                        </TableHead>
                        <TableBody>

                        </TableBody>
                        {accountFamily.map((row) => (
                           <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell component="th" scope="row">{row.firstName}</TableCell>
                              <TableCell align="right">Member</TableCell>
                              <TableCell component="th" scope="row">
                                 <IconButton aria-label="delete" onClick={() => deleteFamilyMember(row.id)}>
                                    <DeleteIcon />
                                 </IconButton>
                              </TableCell>
                           </TableRow>
                        ))}
                     </Table>
                  </TableContainer>
               </Box>
            </div>
         </Paper>
         {/*Add Family Member Modal*/}
         <AddFamily open={openNewFamilyMemberModal} accountId={user?.accountId} serverUrl={appConfig.host} closeHandler={handleCloseNewFamilyMemberModal} saveHandler={setAccountFamilyLoading}/>
      </Root>
   );


}